<template>
    <section class="compras-facturas px-3">
        <titulo-divisor titulo="Facturas">
            <div class="row">
                <div class="col auto">
                    <search :data-to-search="listadoFacturas.data" placeholder="Buscar.." :auto-search="true" @filtered="handleFiltered" />
                </div>
                <div v-if="cantFiltros == 0" class="col-auto my-auto pl-1 icon-option">
                    <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                        <button class="btn btn-square32-5d ml-2 cr-pointer" @click="abrirModalFiltros">
                            <i class="icon-filter text-white" />
                        </button>
                    </el-tooltip>
                </div>
                <div v-if="cantFiltros > 0" class="col-auto filtro-contador my-auto pl-1">
                    <div class="d-flex border-general br-4">
                        <button class="btn btn-square32-5d cr-pointer" @click="abrirModalFiltros">
                            <i class="icon-filter text-white" />
                        </button>
                        <div class="d-middle px-2">
                            <p class="f-12">3</p>
                            <i class="icon-close-circle f-11 text-muted pl-1 cr-pointer" @click="limpiarFiltro" />
                        </div>
                    </div>
                </div>
            </div>
        </titulo-divisor>
        <tabla-general :mostrarBuscador="false" alturaTabla="500px" :data="listadoFacturas.data" :servidorData="listadoFacturas.server" >
            <el-table-column label="Orden" prop="id" align="center" width="80">
                <template slot-scope="scope">
                    <p class="f-12 cr-pointer f-600" @click="verFactura(scope.row.id)">
                        {{ scope.row.id }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Ordenes de compra" prop="ordenes_compra">
                <template slot-scope="scope">
                    <div class="d-flex">
                        <div v-for="(orden,i) in Object.values(scope.row.ordenes_compra)" :key="i" class="mb-1 mx-2">
                            <p class="f-12">{{ orden }}</p>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="Fecha pago" prop="fecha_pago">
                <template slot-scope="scope">
                    <p class="f-12">
                        {{ scope.row.fecha_pago }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Valor" prop="valor">
                <template slot-scope="scope">
                    <p class="f-12">
                        {{ formatoMoneda(scope.row.valor) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Pago" prop="estado">
                <template slot-scope="scope">
                    <p class="f-12 tres-puntos">
                        {{ scope.row.estado }}
                    </p>
                </template>
            </el-table-column>
        </tabla-general>
        <!-- partials -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-12 mb-4">
                    <p class="input-label-top">Proveedores</p>
                    <el-select v-model="billFilter.id_proveedores" placeholder="Seleccionar proveedor" size="small" class="w-100" multiple>
                        <el-option v-for="proveedor in filtro_proveedores" :key="proveedor.id" :label="proveedor.proveedor" :value="proveedor.id" />
                    </el-select>
                </div>
                <div class="col-12 mb-4">
                    <p class="input-label-top">Fecha de pago</p>
                    <el-date-picker v-model="billFilter.fecha_pago" type="date" class="w-100" size="small" placeholder="Seleccionar un día" />
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top">Rango de valor</p>   
                    <el-slider v-model="billFilter.rango" range :max="10000" :format-tooltip="formatTooltipRange" />
                </div>
                <div class="col-12 mb-4">
                    <p class="input-label-top">Estado</p>
                    <el-select v-model="billFilter.id_estado" placeholder="Seleccionar estado" size="small" class="w-100">
                        <el-option v-for="estado in filtro_estados" :key="estado.id" :label="estado.estado" :value="estado.id" />
                    </el-select>
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="limpiarFiltro"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filtrarMateriales"> Filtrar </button>
            </div>
        </modal-lateral>
    </section>

</template>

<script>
import { BillFilterFactory } from '~/domain/filters/compras/facturas/factories/BillFilterFactory'
import {mapGetters, mapActions} from 'vuex'

export default {
    data(){
        return{
            listadoFacturas: [],
            buscarListado:'',
            cantFiltros: 0,
            inputNombreMaterial: '',
            inputCantPlanes: '',
            billFilter: BillFilterFactory.instantiateEmpty()
        }
    },
    computed: {
        ...mapGetters({
            facturas: 'compras/facturas/facturas',
            filtro_proveedores: 'compras/facturas/filtro_proveedores',
            filtro_estados: 'compras/facturas/filtro_estados',
        }),
        id: {
            get(){
                return this.$store.getters['compras/facturas/id']
            },
            set(val){
                this.$store.commit('compras/facturas/set_id', val)
            }
        }
    },
    watch: {
        "$route.params.id"(val){
            console.log(val);
        }
    },
    methods:{
        ...mapActions({
            obtenerFacturas: 'compras/facturas/Action_get_facturas',
            obtenerFiltroProveedores: 'compras/facturas/Action_get_filtro_proveedores',
            obtenerFiltroEstados: 'compras/facturas/Action_get_filtro_estados',
        }),

        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        formatTooltipRange(val){
            return this.formatoMoneda(val)
        },
        async filtrarMateriales(){
            this.billFilter.fecha_pago = this.$options.filters['helper-fecha'](this.billFilter.fecha_pago, 'YYYY-MM-DD')
            this.billFilter.valor_inicial = this.billFilter.rango[0]
            this.billFilter.valor_final = this.billFilter.rango[1]
            
            await this.obtenerFacturas({...this.billFilter, rango: undefined})

            this.listadoFacturas = this.facturas
            this.$router.push({query: {...this.billFilter.clean(),  rango: undefined} })

            this.cantFiltros = Object.values(this.billFilter.clean()).map(value => value != null).length
            this.$refs.abrirModalFiltros.toggle()
        },
        async limpiarFiltro(){
            this.cantFiltros = 0
            this.billFilter = BillFilterFactory.instantiateEmpty()

            await this.obtenerFacturas(this.billFilter)
            this.$router.push({query: {}})
            this.$refs.abrirModalFiltros.toggle()
        },
        verFactura(id){
            this.$router.push({ name: 'orden.compra.facturas.ver', params: { id_factura: id } })
        },
        handleFiltered(data){
            if(data.length){
                this.listadoFacturas.data = data
            } else {
                this.listadoFacturas.data = this.facturas.data
                this.$notify({title: 'No se encontraron resultados', type:'info'})
            }
        }
    },
    created(){
        this.id = this.$route.params.id
        this.$try(() => {
            this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', ['compras.matrix', 'orden.compra.facturas'])
        })
    },
    async mounted(){
        await this.obtenerFiltroProveedores()
        await this.obtenerFiltroEstados()

        if(this.$route.query !== {}){
            console.log('sin cuerri');
            await this.obtenerFacturas(this.billFilter.clean())
        } else {
            this.billFilter = BillFilterFactory.instantiate(this.$route.query)
            this.cantFiltros = Object.values(this.billFilter.clean()).map(value => value != null).length

            await this.obtenerFacturas(this.billFilter)
        }
        this.listadoFacturas = this.facturas
    }
}
</script>

<style>

</style>