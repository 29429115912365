import { BillFilter } from "../BillFilter";

export class BillFilterFactory {
    static instantiate(obj){
        return new BillFilter(obj)
    }

    static instantiateEmpty(){
        return new BillFilter({
            fecha_pago: null,
            valor_inicial: null,
            valor_final: null,
            id_estado: null,
            id_proveedores: null,
            rango: null,
        })
    }
}